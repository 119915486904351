<template>
  <div id="materialEntry">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="filterItem">
        <div style="margin-right:10px;min-width:26px">品名</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.productName"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:26px">单据编号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.ztmeDocNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">公司订单号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.companyOrderNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:26px">状态</div>
        <Select
          slot="list"
          style="width: 150px"
          v-model="listData.params.status"
          @on-change="getTableDataList"
        >
          <Option
            v-for="item in statusList"
            :value="item"
            :key="item + 'status'"
            >{{ item }}</Option
          >
        </Select>
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">单据类型</div>
        <Select
          slot="list"
          style="width: 150px"
          v-model="listData.params.docType"
          @on-change="getTableDataList"
        >
          <Option v-for="item in typeList" :value="item" :key="item + 'type'">{{
            item
          }}</Option>
        </Select>
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">单据日期</div>
        <DatePicker
          v-model="listData.params.dateRange"
          format="yyyy-MM-dd"
          type="daterange"
          placeholder="请选择日期范围"
          style="width: 220px"
          @on-change="changeDate"
        ></DatePicker>
      </div>
      <div class="filterItem">
        <Button @click="customFilter"
          >自定义筛选
          <i v-if="!customShow" class="iconfont iconicon-xia"></i>
          <i v-if="customShow" class="iconfont iconicon-shang"></i>
        </Button>
      </div>
    </div>
    <!-- 自定义筛选选择 -->
    <!-- <div>
      <CustomFilter :customShow="customShow" :customSetting="customSetting" :alreadySetting="alreadySetting"></CustomFilter>
    </div> -->
    <div class="custom" v-if="customShow">
      <!-- <div class="triangle"></div> -->
      <div class="check" v-if="customSetting">
        <Checkbox
          :indeterminate="indeterminate"
          :value="checkAll"
          @click.prevent.native="handleCheckAll"
          >全选</Checkbox
        >
        <div class="border"></div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
          <Checkbox
            v-for="item in checkList"
            :key="item.index"
            :label="item.label"
          ></Checkbox>
        </CheckboxGroup>
        <div class="btn">
          <Button type="primary" @click="confirmSetting">确定</Button>
        </div>
      </div>
      <!-- 自定义筛选确认后 -->
      <div class="searchSetting" v-if="alreadySetting">
        <div class="settingFilter">
          <div
            class="filterItem"
            v-for="item in selectedCheckList"
            :key="item.key"
          >
            <div style="margin-right:10px">{{ item.label }}</div>
            <Input
              placeholder="请输入搜索内容"
              style="width: 212px"
              v-model.trim="listData.params[item.key]"
              @keyup.enter.native="getTableDataList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh('main')">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div>
          <div class="item"></div>
          <div class="item" @click="exportMaterialEntryList">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="getTableDataList">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>
    <Table
      :columns="tableRealColumns"
      :data="tableData"
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
    ></Table>
    <Page
      class-name="page"
      :total="listDataTotal"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.params.pageSize"
      :current="listData.params.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <el-drawer
      title="原材料进仓细表"
      :visible.sync="drawerShow"
      size="570px"
      direction="btt"
    >
      <div class="button">
        <div class="buttonRow">
          <div class="left">
            <div class="item" @click="refresh('detail')">
              <i class="iconfont iconicon-shuaxin"></i>
              刷新
            </div>
            <div class="item" @click="showTableTitleDialogDetail">
              <i class="iconfont iconicon-chuansuokuang"></i>
              表格设置
            </div>
          </div>
        </div>
      </div>
      <Table
        :columns="detailsTableRealColumns"
        :data="detailsTableData"
        show-summary
        :summary-method="handleSummaryDetail"
        border
        :loading="loadingDetail"
      ></Table>
      <Page
        class-name="page"
        :total="detailsListData.total"
        show-elevator
        show-sizer
        show-total
        :page-size="detailsListData.pageSize"
        :current="detailsListData.pageNum"
        @on-page-size-change="pageSizeDetailsChange"
        @on-change="pageNumberDetailsChange"
      />
    </el-drawer>
    <!-- 穿梭框的弹出框 -->
    <TableSetting
      :routerPath="routerPath"
      :theadDateReal="bindTheadDateReal"
      @tableDataList="bindTableDataList"
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
    ></TableSetting>
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";

export default {
  components: {
    TableSetting
  },
  data() {
    return {
      loading: false,
      loadingDetail: true,
      routerPath: "",
      bindTheadDateReal: [],
      bindTableDataList: [],
      searchList: [
        {
          index: 1,
          name: "坯布指示单号"
        },
        {
          index: 2,
          name: "坯布货号"
        },
        {
          index: 3,
          name: "单据编号"
        }
      ],
      chuansuoDialogVisible: false,
      typeList: [],
      statusList: [],
      customShow: false,
      customSetting: true,
      alreadySetting: false,
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      selectedCheck: [], // 已选中的可选条件组成的数组
      selectedCheckList: [], // 已选中的可选条件组成的数组list
      checkList: [
        {
          index: 1,
          label: "关联应付单号",
          key: "payableNumber"
        },
        {
          index: 2,
          label: "关联单号(采购单号）",
          key: "orderNumber"
        },
        {
          index: 3,
          label: "备注",
          key: "remarks"
        }
      ],
      listData: {
        params: {
          ztmeDocNumber: "",
          productName: "",
          companyOrderNumber: "",
          status: "",
          payableNumber: "",
          orderNumber: "",
          remarks: "",
          docType: "",
          dateRange: [],
        },
        pageSize: 10,
        pageNum: 1
      },
      listDataTotal: 0,
      currentId: "",
      ztmeClothQuantityTotal: "",
      ztmeTotalNumTotal: "",
      tableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: 65,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    fontSize: "14px",
                    color: "#256DE6",
                    cursor: "pointer"
                  },
                  on: {
                    click: () => {
                      this.drawerShow = true;
                      this.currentId = params.row.ztmeId;
                      this.getTableDataList1();
                    }
                  }
                },
                "详情"
              )
            ]);
          }
        },
        {
          title: "状态",
          key: "ztmeStatus",
          minWidth: 100
        },
        {
          title: "单据编号",
          key: "ztmeDocNumber",
          minWidth: 130
        },
        {
          title: "单据日期",
          key: "ztmeDocDate",
          minWidth: 200
        },
        {
          title: "单据类型",
          key: "ztmeDocType",
          minWidth: 130
        },
        {
          title: "担当",
          key: "ztmeTakeOn",
          minWidth: 100
        },
        {
          title: "备注",
          key: "ztmeRemarks",
          minWidth: 150
        },
        {
          title: "公司订单号",
          key: "ztmeCompanyOrderNumber",
          minWidth: 120
        },
        {
          title: "单位编号",
          key: "ztmeUnitNumber",
          minWidth: 125
        },
        {
          title: "单位名称",
          key: "ztmeUnitName",
          minWidth: 250
        },
        {
          title: "匹数合计",
          key: "ztmeClothQuantity",
          minWidth: 100
        },
        {
          title: "数量合计(KG)",
          key: "ztmeTotalNum",
          minWidth: 130
        },
        {
          title: "品名",
          key: "ztmeProductName",
          minWidth: 300
        },
        {
          title: "详细描述",
          key: "ztmeDetails",
          minWidth: 100
        },
        {
          title: "关联应付单号",
          key: "ztmePayableNumber",
          minWidth: 150
        },
        {
          title: "采购单单号",
          key: "ztmeOrderNumber",
          minWidth: 130
        }
      ],
      tableRealColumns: [],
      tableData: [],
      drawerShow: false,
      detailsListData: {
        pageSize: 8,
        pageNum: 1,
        total: 0
      },
      detailsTableRealColumns: [],
      detailsTableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.detailsListData.pageNum - 1) *
                  this.detailsListData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "批次序号",
          key: "ztmeiBatchNum",
          minWidth: 150
        },
        {
          title: "采购合同",
          key: "ztmeiPurchaseContractNum",
          minWidth: 150
        },
        {
          title: "织造单号",
          key: "ztmeiWeaveOrderNum",
          minWidth: 200
        },
        {
          title: "公司订单号",
          key: "ztmeiCompanyOrderNum",
          minWidth: 200
        },
        {
          title: "原料名称",
          key: "name",
          minWidth: 100
        },
        {
          title: "规格",
          key: "ztmeiSpecification",
          minWidth: 350
        },
        {
          title: "批次",
          key: "ztmeiBatch",
          minWidth: 100
        },
        {
          title: "颜色",
          key: "ztmeiColor",
          minWidth: 100
        },
        {
          title: "色号",
          key: "ztmeiColorNum",
          minWidth: 125
        },
        {
          title: "库存件数",
          key: "ztmeiClothNum",
          minWidth: 100
        },
        {
          title: "库存数量(KG)",
          key: "ztmeiNum",
          minWidth: 130
        },
        {
          title: "库存单价",
          key: "ztmeiPrice",
          minWidth: 100
        },
        {
          title: "结算单价",
          key: "ztmeiAccountPrice",
          minWidth: 100
        },
        {
          title: "结算数量",
          key: "ztmeiSettleNum",
          minWidth: 100
        },
        {
          title: "单位",
          key: "ztmeiUnit",
          minWidth: 100
        },
        {
          title: "存放位置",
          key: "ztmeiLocation",
          minWidth: 100
        },
        {
          title: "备注",
          key: "ztmeiRemarks",
          minWidth: 125
        },
        {
          title: "已结算数量",
          key: "ztmeiSettledNum",
          minWidth: 120
        },
        {
          title: "供应商",
          key: "ztmeiSupplier",
          minWidth: 230
        },
        {
          title: "校验货号",
          key: "ztmeiCheckoutCargoNum",
          minWidth: 100
        },
        {
          title: "加工色号",
          key: "ztmeiProcessColorNum",
          minWidth: 100
        },
        {
          title: "进仓日期",
          key: "ztmeiEntryDate",
          minWidth: 160
        },
        {
          title: "进仓单号",
          key: "ztmeiEntryCargoNum",
          minWidth: 100
        }
      ],
      detailsTableData: [],
      summaryDetailList: {
        ztmeiClothNumTotal: "",
        ztmeiNumTotal: "",
        ztmeiSettleNumTotal: "",
        ztmeiSettledNumTotal: ""
      }
    };
  },
  created() {
    this.getTableDataList();
    this.getDocTypeList();
    this.getStatusList();
  },
  methods: {

        changeDate() {
      const date = this.listData.params.dateRange;
      if (date[0] !== "" && date[0] !== null) {
        this.listData.params.docDateStart = this.moment(date[0]).format(
          "YYYY-MM-DD"
        );
        this.listData.params.docDateEnd = this.moment(date[1]).format(
          "YYYY-MM-DD"
        );
      } else {
        this.listData.params.docDateStart = "";
        this.listData.params.docDateEnd = "";
      }
      this.getTableDataList();
    },

    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    pageSizeDetailsChange(pageSize) {
      this.detailsListData.pageSize = pageSize;
      this.detailsListData.pageNum = 1;
      this.getTableDataList1();
    },
    pageNumberDetailsChange(pageNum) {
      this.detailsListData.pageNum = pageNum;
      this.getTableDataList1();
    },
    // 主表显示弹出框
    showTableTitleDialog() {
      this.routerPath =
        "/dtsum/zongtong/material/materialController/materialEntrySearch";
      this.bindTheadDateReal = this.tableRealColumns;
      this.bindTableDataList = this.getTableDataList;
      this.chuansuoDialogVisible = true;
    },
    // 细表显示弹出框
    showTableTitleDialogDetail() {
      this.routerPath =
        "/dtsum/zongtong/material/materialController/materialEntryDetail";
      this.bindTheadDateReal = this.detailsTableRealColumns;
      this.bindTableDataList = this.getTableDataList1;
      this.chuansuoDialogVisible = true;
    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      if (this.checkAll) {
        this.checkAllGroup = this.checkList.map(function(item) {
          return item.label;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.checkList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    // 点击自定义筛选
    customFilter() {
      if (this.selectedCheck.length > 0) {
        this.customShow = true;
        if (this.alreadySetting) {
          this.customSetting = true;
          this.alreadySetting = false;
          this.checkAllGroup = this.selectedCheck;
          if (this.checkList.length !== this.checkAllGroup.length) {
            this.indeterminate = true;
          } else {
            this.indeterminate = false;
            this.checkAll = true;
          }
        } else {
          this.customSetting = false;
          this.alreadySetting = true;
        }
      } else {
        this.customShow = !this.customShow;
        this.checkAllGroup = this.selectedCheck;
        this.indeterminate = false;
      }
    },
    // 确定自定义筛选
    confirmSetting() {
      this.selectedCheck = this.checkAllGroup;
      if (this.selectedCheck.length > 0) {
        this.customSetting = false;
        this.alreadySetting = true;
        this.customShow = true;
        var arr = [];
        this.selectedCheck.forEach((item) => {
          this.checkList.forEach((item1) => {
            if (item === item1.label) {
              arr.push(item1);
            }
          });
        });
        this.selectedCheckList = arr;
      } else {
        this.customSetting = true;
        this.alreadySetting = false;
        this.customShow = false;
        this.indeterminate = false;
      }
    },
    // 获取单据类型接口
    getDocTypeList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "materialentertype"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.typeList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取状态接口
    getStatusList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "materialenterstatus"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.statusList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 刷新表格
    refresh(params) {
      if (params === "main") {
        this.getTableDataList();
      } else {
        this.getTableDataList1();
      }
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 导出列表
    exportMaterialEntryList() {
      const data = this.listData.params;
      window.location.href = `${this.baseUrl}/dtsum/zongtong/material/materialController/exportMaterialEntryList?productName=${data.productName}&companyOrderNumber=${data.companyOrderNumber}&status=${data.status}&payableNumber=${data.payableNumber}&orderNumber=${data.orderNumber}&remarks=${data.remarks}&docType=${data.docType}&ztmeDocNumber=${data.ztmeDocNumber}`;
    },
    // 获取原材料进仓列表
    getTableDataList() {
      this.statusList[3] = "ppp";
      // this.$set(this.statusList, 3, "99");
      this.loading = true;
      this.tableRealColumns.length = 0;
      this.axios({
        url: "/dtsum/zongtong/material/materialController/materialEntrySearch",
        method: "get",
        params: this.listData
      })
        .then((res) => {
          this.loading = false;
          if (res.data.success === 1) {
            if (this.tableRealColumns.length === 0) {
              this.tableRealColumns.push(this.tableColumns[0]);
              this.tableRealColumns.push(this.tableColumns[1]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.tableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.tableColumns[j].key
                  ) {
                    this.tableRealColumns.push(this.tableColumns[j]);
                  }
                }
              }
              this.tableData = res.data.body.materialEntryList.list;
              this.listDataTotal = res.data.body.materialEntryList.total;
              this.listData.pageSize = res.data.body.materialEntryList.pageSize;
              this.listData.pageNum = res.data.body.materialEntryList.pageNum;
              this.ztmeClothQuantityTotal =
                res.data.body.ztmeClothQuantityTotal;
              this.ztmeTotalNumTotal = res.data.body.ztmeTotalNumTotal;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztmeClothQuantity") {
          sums[key] = {
            key,
            value: this.ztmeClothQuantityTotal
          };
        } else if (key === "ztmeTotalNum") {
          sums[key] = {
            key,
            value: this.ztmeTotalNumTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    },
    // 获取进仓细表
    getTableDataList1() {
      this.loadingDetail = true;
      this.detailsTableRealColumns.length = 0;
      this.axios({
        url: "/dtsum/zongtong/material/materialController/materialEntryDetail",
        method: "get",
        params: {
          ztmeId: this.currentId,
          pageSize: this.detailsListData.pageSize,
          pageNum: this.detailsListData.pageNum
        }
      })
        .then((res) => {
          this.loadingDetail = false;
          if (res.data.success === 1) {
            if (this.detailsTableRealColumns.length === 0) {
              this.detailsTableRealColumns.push(this.detailsTableColumns[0]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.detailsTableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.detailsTableColumns[j].key
                  ) {
                    this.detailsTableRealColumns.push(
                      this.detailsTableColumns[j]
                    );
                  }
                }
              }
              this.detailsTableData = res.data.body.materialEntryDetail.list;
              this.detailsListData.total =
                res.data.body.materialEntryDetail.total;
              this.detailsListData.pageSize =
                res.data.body.materialEntryDetail.pageSize;
              this.detailsListData.pageNum =
                res.data.body.materialEntryDetail.pageNum;
              this.summaryDetailList.ztmeiClothNumTotal =
                res.data.body.ztmeiClothNumTotal;
              this.summaryDetailList.ztmeiNumTotal =
                res.data.body.ztmeiNumTotal;
              this.summaryDetailList.ztmeiSettleNumTotal =
                res.data.body.ztmeiSettleNumTotal;
              this.summaryDetailList.ztmeiSettledNumTotal =
                res.data.body.ztmeiSettledNumTotal;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadingDetail = false;
        });
    },
    handleSummaryDetail({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztmeiClothNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmeiClothNumTotal
          };
        } else if (key === "ztmeiNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmeiNumTotal
          };
        } else if (key === "ztmeiSettleNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmeiSettleNumTotal
          };
        } else if (key === "ztmeiSettledNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmeiSettledNumTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>
#materialEntry {
  .filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      margin-right: 50px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
      i {
        margin-left: 9px;
      }
      &:last-child {
        width: 132px;
        margin-right: 0px;
        button {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
  .custom {
    position: relative;
    width: 100%;
    // min-height: 95px;
    background: #f4f7fe;
    margin-top: -35px;
    margin-bottom: 39px;
    padding: 16px 8px;
    .triangle {
      position: absolute;
      top: -14px;
      right: 60px;
      width: 0px;
      height: 0px;
      border-top: 0px solid transparent;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #f5f5f5;
    }
    .border {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: #dcdada;
      margin-right: 20px;
      vertical-align: middle;
    }
    .ivu-checkbox-wrapper {
      margin-right: 20px;
    }
    .ivu-checkbox-group {
      display: inline-block;
    }
    .btn {
      margin-top: 17px;
      text-align: right;
    }
  }
  .searchSetting {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    .settingFilter {
      display: flex;
      flex-wrap: wrap;
      font-size: $--fontsize-small;
      .filterItem {
        display: inline-flex;
        margin-right: 50px;
        margin-bottom: 20px;
        height: 32px;
        line-height: 32px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .settingBtn {
      display: flex;
      line-height: 32px;
      .border {
        width: 1px;
        height: 14px;
        margin-right: 14px;
        background: #dcdada;
        margin-top: 9px;
      }
      .changebtn {
        color: #256de6;
        cursor: pointer;
      }
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    margin-top: -20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(3) {
            margin-right: 20px;
          }
          &:nth-child(4) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton {
          width: 130px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
<style lang="scss">
#materialEntry {
  .el-drawer {
    // height: 60% !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .el-drawer__header {
      margin-bottom: 12px;
      padding: 20px 30px 0px 30px;
      font-size: 16px;
      color: #212b36;
    }
    .el-drawer__body {
      // height: 60% !important;
      overflow: auto;
      padding: 0px 30px 30px 30px;
      .button {
        margin-top: 0px;
        margin-bottom: 17px;
      }
    }
  }
}
</style>
